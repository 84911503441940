$(document).ready(function () {
	//Declare normal variable javascript
	//Hide element when smaller than 1025
	if ($(window).width() < 1025) {
		$(".main-menu-nav").fadeIn(function () {
			$(".main-menu-nav").css({
				display: "flex"
			});
		});
	}


	//Library init
	ZoomImg();


	$('[data-fancybox=""]').fancybox({
		smallBtn: true,
		toolbar: true,
		type: "html",
		clickSlide: 'toggleControls'
	});
	//Declare function Javascript

	toggleMobileMenu();
	swiperInit();
	mappingMenuRight();
	mappingSearch();
	mappingLinkZone();
	tabActive();
	toggleBSCI();
	toggleSupport()
	toggleProductZone();
	toggleWatchMore();
	sideNavigation();
	filterToggle();
	try {

		linkSectionAbout();

	} catch (error) {

	}
	appendComment();
});
window.onscroll = function () {
	// scrollHeader();
	fixHeader();
	fixAboutNav();

};

function ZoomImg() {
	if ($(window).width() > 1024) {
		$(".swiper-slide-active .zoom-img").ezPlus({
			gallery: "gallery_01",
			cursor: "pointer",
			easing: 'true',
			galleryActiveClass: "active",
			imageCrossfade: true,
			loadingIcon: "http://www.elevateweb.co.uk/spinner.gif",
			zoomWindowWidth: "500",
			zoomWindowHeight: "500",
			borderSize: "1",
			borderColour: "#ff4900",
			zoomWindowFadeIn: 500,
			zoomWindowFadeOut: 500,
			lensFadeIn: 500,
			lensFadeOut: 500

		});
	}
}

function appendComment() {
	if ($("body").hasClass('product-detail-page')) {
		$('.commentpanel').appendTo('.product-detail-commment article')
	}
}

$(window).bind("load", function () {
	$('.rcRefreshImage').html('');
	$('.rcRefreshImage').on('click', function () {
		$(this).html('')
	});
});

function tabActive() {
	$(".tab-navigation li a").on("click", function () {
		$(this)
			.parents(".tab-navigation")
			.find("li")
			.removeClass("active");
		$(this)
			.parents("li")
			.addClass("active");

		var display = $(this).attr("data-type");
		$(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});
}


function toggleMobileMenu() {
	var $hamburger = $(".hamburger");
	$(".main-menu-toggle").on("click", function () {
		$(".mobile-wrapper").toggleClass("active");
		$hamburger.toggleClass("is-active");
	});
}

function fixHeader() {
	if ($(window).width() > 1025) {

		if (
			document.body.scrollTop > 80 ||
			document.documentElement.scrollTop > 80
		) {
			$("#header").addClass("minimize");
			$("#Iframe1").addClass("minimize");
		} else {
			$("#header").removeClass("minimize");
			$("#Iframe1").removeClass("minimize");
		};
	}
}


function swiperInit() {
	var homerSwiper = new Swiper(".home-banner .swiper-container", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		autoplay: {
			delay: 3000
		},
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
		},
	});


	var partner = new Swiper(".swiper-home-product .swiper-container", {
		// Optional parameters
		spaceBetween: 30,
		speed: 1205,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 1,
			},
			350: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 4,
			}
		},

		autoplay: {
			delay: 3000
		},
		navigation: {
			nextEl: ".swiper-home-product  .nav-arrow-next",
			prevEl: ".swiper-home-product  .nav-arrow-prev"
		}
	});

	var topBanner = new Swiper(".top-banner .swiper-container", {
		// Optional parameters
		speed: 1205,
		slidesPerView: 1,
		autoplay: {
			delay: 3000
		}
	});



	var multiRow = new Swiper('.swiper-multi-row .swiper-container', {

		spaceBetween: 30,
		navigation: {
			nextEl: ".swiper-multi-row .nav-arrow-next",
			prevEl: ".swiper-multi-row .nav-arrow-prev"
		},
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 1,
				slidesPerColumn: 2,
			},
			400: {
				slidesPerView: 2,
				slidesPerColumn: 2,
			},
			576: {
				slidesPerView: 3,
				slidesPerColumn: 2,
			},
			1025: {
				slidesPerView: 2,
				slidesPerColumn: 2,
			},
			1280: {
				slidesPerView: 3,
				slidesPerColumn: 2,
			}
		},
	});

	var partner = new Swiper(".swiper-other .swiper-container", {
		// Optional parameters
		spaceBetween: 30,
		speed: 1205,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 1,
			},
			576: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 4,
			}
		},

		autoplay: {
			delay: 3000
		},
		navigation: {
			nextEl: ".swiper-other .nav-arrow-next",
			prevEl: ".swiper-other .nav-arrow-prev"
		}
	});

	var partner = new Swiper(".swiper-partner .swiper-container", {
		// Optional parameters
		spaceBetween: 0,
		speed: 1205,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 5,
			}
		},

		autoplay: {
			delay: 3000
		},
		navigation: {
			nextEl: ".swiper-partner .nav-arrow-next",
			prevEl: ".swiper-partner .nav-arrow-prev"
		}
	});
	var partner = new Swiper(".swiper-customer .swiper-container", {
		// Optional parameters
		spaceBetween: 0,
		speed: 1205,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			768: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 6,
			}
		},

		autoplay: {
			delay: 3000
		},
		navigation: {
			nextEl: ".swiper-customer .nav-arrow-next",
			prevEl: ".swiper-customer .nav-arrow-prev"
		}
	});

	var productThumbs = new Swiper(
		".swiper-product-thumbs  .swiper-container", {
			spaceBetween: 30,
			freeMode: false,
			breakpointsInverse: true,
			breakpoints: {
				320: {
					slidesPerView: 3,
					direction: "horizontal",
					spaceBetween: 10,
				},
				576: {
					slidesPerView: 3,
					direction: "horizontal",

				},
				1025: {
					slidesPerView: 4,
					spaceBetween: 10,
					direction: "vertical"
				},
				1440: {
					slidesPerView: 5,
					spaceBetween: 10,
					direction: "vertical"
				}
			},
			watchSlidesVisibility: true,
			watchSlidesProgress: true,

		}
	);

	var productMain = new Swiper(".swiper-product-main .swiper-container", {
		slidesPerView: 1,
		centeredSlides: true,
		effect: "fade",
		navigation: {
			nextEl: ".product-thumb-next",
			prevEl: ".product-thumb-prev"
		},
		fadeEffect: {
			crossFade: true
		},
		speed: 750,
		loop: false,
		thumbs: {
			swiper: productThumbs
		},
		on: {
			init: function () {
				ZoomImg();
			},
			slideChangeTransitionEnd: function () {
				ZoomImg();
			},
		},
	});

}




function mappingMenuRight() {
	return new MappingListener({
		selector: ".main-menu",
		mobileWrapper: ".mobile-wrapper",
		mobileMethod: "appendTo",
		desktopWrapper: ".header-wrapper-bottom",
		desktopMethod: "appendTo",
		breakpoint: 1025
	}).watch();
}

function mappingLinkZone() {
	return new MappingListener({
		selector: ".link-to-zone",
		mobileWrapper: ".mobile-wrapper",
		mobileMethod: "appendTo",
		desktopWrapper: ".search-wrapper",
		desktopMethod: "insertBefore",
		breakpoint: 1025
	}).watch();
}

function mappingSearch() {
	return new MappingListener({
		selector: ".search-wrapper",
		mobileWrapper: ".mobile-wrapper",
		mobileMethod: "appendTo",
		desktopWrapper: ".language-wrapper",
		desktopMethod: "insertBefore",
		breakpoint: 1025
	}).watch();
}



function fixAboutNav() {

	if ($("body").hasClass("about-us-section")) {
		if ($(window).width() > 1024) {
			if (
				document.body.scrollTop > 350 ||
				document.documentElement.scrollTop > 350
			) {
				$(".about-zone-navigation").addClass("minimize");
			} else {
				$(".about-zone-navigation").removeClass("minimize");
			}
		} else if ($(window).width() > 768.1 && $(window).width() < 1025) {
			if (
				document.body.scrollTop > 300 ||
				document.documentElement.scrollTop > 300
			) {
				$(".about-zone-navigation").addClass("minimize");
			} else {
				$(".about-zone-navigation").removeClass("minimize");
			}
		} else if ($(window).width() < 768.1) {
			if (
				document.body.scrollTop > 100 ||
				document.documentElement.scrollTop > 100
			) {
				$(".about-zone-navigation").addClass("minimize");
			} else {
				$(".about-zone-navigation").removeClass("minimize");
			}
		}
	}
}



function scrollHeader() {
	var prevScrollpos = window.pageYOffset;
	window.onscroll = function () {
		var currentScrollPos = window.pageYOffset;
		if (prevScrollpos > currentScrollPos) {
			document.getElementById("header").style.top = "0";
		} else {
			document.getElementById("header").style.top = "-100px";
		}
		prevScrollpos = currentScrollPos;
	}
}


function toggleProductZone() {
	$(".product-side-navigation li  a").click(function (e) {
		e.preventDefault();
		if (
			!$(this)
			.parent()
			.hasClass("active")
		) {
			$(".product-side-navigation li .drop-down").slideUp();
			$(this)
				.next()
				.slideToggle();
			$(".product-side-navigation >li").removeClass("active");
			$(this)
				.parent()
				.addClass("active");
		} else {
			$(this)
				.next()
				.slideToggle();
			$(".product-side-navigation >li").removeClass("active");
		}
	});
}

function toggleBSCI() {
	$(".bsci-figure > .figure-image").click(function (e) {
		e.preventDefault();
		if (
			!$(this)
			.parent()
			.hasClass("active")
		) {
			$(".bsci-figure .figcaption").slideUp();
			$(this)
				.next()
				.slideToggle();
			$(".bsci-figure").removeClass("active");
			$(this)
				.parent()
				.addClass("active");
		} else {
			$(this)
				.next()
				.slideToggle();
			$(".bsci-figure").removeClass("active");
		}
	});
}

function toggleSupport() {
	$(".toggle-item > .title").click(function (e) {
		e.preventDefault();
		if (
			!$(this)
			.parent()
			.hasClass("active")
		) {
			$(".toggle-item article").slideUp();
			$(this)
				.next()
				.slideToggle();
			$(".toggle-item").removeClass("active");
			$(this)
				.parent()
				.addClass("active");
		} else {
			$(this)
				.next()
				.slideToggle();
			$(".toggle-item").removeClass("active");
		}
	});
}



function toggleWatchMore() {

	let button = $(".read-more-wrapper .btn-primary");

	let content = $(".read-more-wrapper article ");

	button.on("click", function () {
		content.toggleClass('active')

	});

}

function linkSectionAbout() {
	if (window.location.hash) {
		var hash = window.location.hash;
		let offset = 150;
		$('html, body').animate({
			scrollTop: $(hash).offset().top - offset
		}, 800, 'linear');
	}
	$(".main-menu li:nth-child(4) a").on('click', function (event) {
		if (this.hash !== "") {
			let offset = 100;
			var hash = this.hash;
			$('html, body').animate({
				scrollTop: $(hash).offset().top - offset
			}, 800, function () {
				window.location.hash = hash;
			});
		} 
	});
	$(".about-zone-navigation a").on('click', function (event) {

		// Make sure this.hash has a value before overriding default behavior
		if (this.hash !== "") {
			// Prevent default anchor click behavior
			let offset = 100;
			// Store hash
			var hash = this.hash;

			// Using jQuery's animate() method to add smooth page scroll
			// The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
			$('html, body').animate({
				scrollTop: $(hash).offset().top - offset
			}, 800, function () {

				// Add hash (#) to URL when done scrolling (default click behavior)
				window.location.hash = hash;
			});
		} // End if
	});

}


function sideNavigation() {
	$(".side-navigation > li > .title em").click(function (e) {
		e.preventDefault();
		if (!$(this)
			.parent()
			.parent()
			.hasClass("active")
		) {
			$(".side-navigation li .nav-sub").slideUp();
			$(this)
				.parent()
				.next()
				.slideToggle();
			$(".side-navigation li").removeClass("active");
			$(this)
				.parent()
				.parent()
				.addClass("active");
		} else {
			$(this)
				.parent()
				.next()
				.slideToggle();
			$(".side-navigation li").removeClass("active");
		}
	});
}

function filterToggle() {
	$('.mobile-toggle-filter').on('click', function () {
		$('.product-zone-navigation').slideToggle();
	})
}